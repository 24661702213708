// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/Users/inigo/projects/websites/idest.github.io/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-about-js": () => import("/Users/inigo/projects/websites/idest.github.io/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/inigo/projects/websites/idest.github.io/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-compass-js": () => import("/Users/inigo/projects/websites/idest.github.io/src/pages/compass.js" /* webpackChunkName: "component---src-pages-compass-js" */),
  "component---src-pages-index-js": () => import("/Users/inigo/projects/websites/idest.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/Users/inigo/projects/websites/idest.github.io/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-stereobox-js": () => import("/Users/inigo/projects/websites/idest.github.io/src/pages/stereobox.js" /* webpackChunkName: "component---src-pages-stereobox-js" */)
}

exports.data = () => import("/Users/inigo/projects/websites/idest.github.io/.cache/data.json")

